import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import ContactDetails from "./ContactDetails";
import fskatt from "../assets/fskatt.png";
import swish from "../assets/swish.png";
import waveTop from "../assets/waveFooter.svg";
function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://Tigerbilar.se/">
        Tigerbilar.se
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

export default function Footer() {
  return (
    <>
    <Box
        sx={{
          width: "100%",
          marginBottom:'-10px',
          marginTop:5
        }}
      >
        <img
          src={waveTop}
          style={{
            width: "100%",
          }}
          alt="Abstract wave pattern in a flowing design, symbolizing fluidity and movement."
          title="Fluid Wave Design"
        />
      </Box>
    <Typography
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "var(--color-lightblue)",
        color: "var(--color-darknavy)",
      }}
    >
      <Container sx={{ my: 2, display: "flex" }}>
        <Grid container spacing={5}>
          <ContactDetails />
          <Grid item xs={6} sm={8} md={4}>
            <Typography
              pb={2}
              variant="h6"
              textAlign={"left"}
              sx={{ borderBottom: "1px solid #eaeaea;" }}
              color= "var(--color-darknavy)"
            >
              Om företaget
            </Typography>
            <Box textAlign={"left"} mt={2}>
              Vi säljer och köper begagnade, trasig och defekt bilar till bra pris.
            </Box>
            <Box textAlign={"left"} mt={2}>
              NAM Trade AB
            </Box>
            <Box textAlign={"left"} mt={2}>
              Org: 5593866063
            </Box>

            <Box textAlign={"left"} mt={2}>
              #Trasig #Avställd #Defekt #Begagnad
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", justifyContent: "center", pl: 0 }}
            item
            xs={6}
            sm={8}
            md={5}
          >
            <Box
              component="img"
              sx={{
                zIndex: 1,
                display: "",
                width: "70%",
                objectFit: "contain",
              }}
              src={swish}
              alt={"Swish logo"}
            />

            <Box
              component="img"
              sx={{
                zIndex: 1,
                display: "",
                width: "40%",
                objectFit: "contain",
              }}
              src={fskatt}
              alt={"f skatt logo"}
            />
          </Grid>
        </Grid>
      </Container>
      <Grid item p={5}>
        <Copyright />
      </Grid>
    </Typography>
    </>
    
  );
}
